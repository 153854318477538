<template>

<div>

    <div class="singleEmailTemplateContainer">
                      <div
                        class="bg-preload"
                        style="height: 230px; width: 100%;border-top-left-radius: 12px; border-top-right-radius: 12px;"
                      >
                        <div class="into-preload"></div>
                      </div>
                    </div>
</div>
</template>


<script>
export default{

}
</script>

<style scoped>

.singleEmailTemplateContainer {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 4, 17, 0.15);
  min-height: 300px;
}


</style>